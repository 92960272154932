







































































































































































































































































import Vue from "vue";
import store from "@/store";
import {
  ACTION_CANDIDATE,
  VIEW_CANDIDATE_DATA,
  VIEW_CANDIDATE_FILTER
} from "@/store/modules/recruiter_admin/constants";
import { mapActions, mapGetters, mapMutations } from "vuex";
import ProfilePicture from "@/components/shared/view_profile/ProfilePicture.vue";
import UserDesignation from "@/components/shared/view_profile/UserDesignation.vue";
import UserRating from "@/components/shared/view_profile/UserRating.vue";
import UserExperience from "@/components/shared/view_profile/UserExperience.vue";
import { get_color_by_name } from "@/utils/colors";
import HighlightsAwards from "@/components/shared/view_profile/HighlightsAwards.vue";
import EducationDetails from "@/components/shared/view_profile/EducationDetails.vue";
import CertificationDetails from "@/components/shared/view_profile/CertificationDetails.vue";
import Personality from "@/components/shared/view_profile/Personality.vue";
import WorkExperience from "@/components/shared/view_profile/WorkExperience.vue";
import SkillsWithGroups from "@/components/shared/SkillsWithGroups.vue";
import {
  CandidateCardViewOptions,
  ViewCandidatesOptions
} from "@/interfaces/recruiter/admin/all_candidates";
import ApprovedDeclinedButton from "@/components/recruiter/admin/ApprovedDeclinedButton.vue";
import {
  ActionCandidate,
  ActionCandidatePayload
} from "@/store/modules/recruiter_admin/interfaces";

export default Vue.extend({
  name: "CandidateProfile",
  components: {
    ApprovedDeclinedButton,
    SkillsWithGroups,
    WorkExperience,
    Personality,
    CertificationDetails,
    EducationDetails,
    HighlightsAwards,
    UserExperience,
    UserRating,
    UserDesignation,

    ProfilePicture
  },
  beforeRouteEnter(to, from, next) {
    const candidate_data =
      store.getters[`recruiter_admin/${VIEW_CANDIDATE_DATA}`];

    if (!candidate_data) next("/recruiter/admin/candidates");
    else next();
  },
  beforeRouteUpdate(to, from, next) {
    const candidate_data =
      store.getters[`recruiter_admin/${VIEW_CANDIDATE_DATA}`];

    if (!candidate_data) next("/recruiter/admin/candidates");
    else next();
  },
  data() {
    return {
      social_icons: {
        linkedin: require("@/assets/logos/linkedin-3.svg"),
        twitter: require("@/assets/logos/twitter.svg"),
        instagram: require("@/assets/logos/instagram.svg"),
        facebook: require("@/assets/logos/facebook.svg")
      },
      active_skill_group: "",
      loading: false
    };
  },
  computed: {
    CandidateCardViewOptions() {
      return CandidateCardViewOptions;
    },
    ViewCandidatesOptions() {
      return ViewCandidatesOptions;
    },
    ...mapGetters("recruiter_admin", {
      candidate: VIEW_CANDIDATE_DATA,
      filter: VIEW_CANDIDATE_FILTER
    })
  },
  methods: {
    ...mapActions("recruiter_admin", {
      action_candidate: ACTION_CANDIDATE
    }),
    ...mapMutations("recruiter_admin", {
      set_candidate_filter: VIEW_CANDIDATE_FILTER
    }),
    transform_resume_icon(icon: HTMLElement): HTMLElement {
      for (let node of icon.children) {
        node.setAttribute("fill", get_color_by_name("white"));
        node.setAttribute("stroke", get_color_by_name("grey"));
      }
      return icon;
    },
    update_skill_group(skill_group: string) {
      if (skill_group === this.active_skill_group) this.active_skill_group = "";
      else this.active_skill_group = skill_group;
    },

    async btn_action(result: boolean) {
      this.loading = true;
      const payload: ActionCandidatePayload = {
        action: result ? ActionCandidate.ACTIVATE : ActionCandidate.DEACTIVATE,
        user_ids: [this.candidate.user_data.id]
      };
      await this.action_candidate(payload);
      if (result) this.set_candidate_filter(ViewCandidatesOptions.APPROVED);
      else this.set_candidate_filter(ViewCandidatesOptions.DECLINED);
      this.loading = false;
    }
  }
});
